import { userProfileSelectors } from '../selectors/userProfileSelectors';
import { useAppSelector } from './useThunkDispatch';

export const useUserProfile = () => {
    const state = useAppSelector(userProfileSelectors.profile);
    return state;
};

export const useIsUserAuthenticated = () => {
    const state = useAppSelector(userProfileSelectors.isAuthenticated);
    return state;
};

export const useIsUserAMember = () => {
    const state = useAppSelector(userProfileSelectors.isMember);
    return state;
};

export const useIsUserCompany = () => {
    const state = useAppSelector(userProfileSelectors.isCompany);
    return state;
};

export const useHasUserAKimCustomerId = () => {
    const state = useAppSelector(userProfileSelectors.hasKimCustomerId);
    return state;
};
