import { type ReactNode } from 'react';
import * as React from 'react';

import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import LoginReminderTooltip from '../LoginReminderTooltip';

interface HeaderMainNavProps {
    stayAfterLoginLogoutRedirect: boolean;
    leftSlot?: ReactNode;
    centerSlot?: ReactNode;
    rightSlot?: ReactNode;
}

const HeaderMainNav = (props: HeaderMainNavProps) => {
    const hideLoginReminder = useAppSelector((state) => state.ui.shared.hideLoginReminder);

    return (
        <>
            <div className="Header-mainNav">
                <div className="Header-group Header-group--space16">{props.leftSlot}</div>

                {props.centerSlot || <div className="Header-group" />}

                {props.rightSlot ? (
                    <div className="Header-group Header-group--space12">{props.rightSlot}</div>
                ) : (
                    <div className="Header-group" />
                )}
            </div>
            <div
                className="u-posAbsolute u-flex u-flexDirectionColumn u-flexGap12 u-paddingAsm"
                style={{ right: 0 }}
            >
                {!hideLoginReminder && (
                    <LoginReminderTooltip
                        stayAfterLoginLogoutRedirect={props.stayAfterLoginLogoutRedirect}
                    />
                )}
            </div>
        </>
    );
};

export default HeaderMainNav;
