import { OverlayPacmanLoader } from '@coop/components';
import * as React from 'react';

import BaseLayout from '../../base/BaseLayout';
import { HeaderNoticesPortal } from '../../base/components/HeaderNotices';
import MainPageLayout from '../../base/components/MainPageLayout';
import { useGroupError } from '../../common/hooks/useGroupError';
import { ErrorGroup } from '../../common/store/structureDefinitions/errorsState';
import EpiContentArea from '../../episerver/components/base/EpiContentArea';
import { useCurrentEpiPage } from '../../episerver/components/hooks/useCurrentEpiPage';
import type { EpiStartPageType } from '../../episerver/components/pages/EpiStartPage/epiStartPageType';

const StartPage = () => {
    // should be loaded from ssr
    const {
        data: startPage,
        error,
        isSuccess: isStartQueryPageSuccess,
        isLoading: isStartQueryPageLoading,
    } = useCurrentEpiPage<EpiStartPageType>({ depth: 5 });

    useGroupError(ErrorGroup.Global, error || undefined);

    if (isStartQueryPageLoading)
        return <OverlayPacmanLoader delay={150} isLoading={isStartQueryPageLoading} />;

    if (!isStartQueryPageSuccess) return null;

    return (
        <BaseLayout>
            <MainPageLayout className="u-marginTmd">
                <HeaderNoticesPortal />
                {startPage.topArea && (
                    <div className="Grid Grid--gutterV">
                        <EpiContentArea items={startPage.topArea} />
                    </div>
                )}
                {startPage.middleArea && (
                    <div className="Grid Grid--gutterV">
                        <EpiContentArea items={startPage.middleArea} />
                    </div>
                )}
                {startPage.bottomArea && (
                    <div className="Grid Grid--gutterV">
                        <EpiContentArea items={startPage.bottomArea} />
                    </div>
                )}
            </MainPageLayout>
        </BaseLayout>
    );
};

export default StartPage;
