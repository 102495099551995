/* eslint-disable no-param-reassign */
import classNames from 'classnames';
import * as React from 'react';

import {
    useIsFromMedium,
    useIsMobile,
} from '../../../common/components/atoms/ResponsiveBreakpoints';
import { useAppSelector } from '../../../common/hooks/useThunkDispatch';
import UpscopeTriggerButton from '../UpscopeTriggerButton';
import ConsentButton from './ConsentButton';
import styles from './Footer.module.less';
import FooterColumns from './FooterColumns';
import FooterContact from './FooterContact';
import FooterLogos from './FooterLogos';
import FooterMobile from './FooterMobile';
import FooterSocial from './FooterSocial';

const BaseFooter = (props: React.PropsWithChildren<{ footer: FrontendFooter }>) => {
    const { footer } = props;

    const isMobile = useIsMobile();

    const isFromMedium = useIsFromMedium();
    const hideFooterMobile = useAppSelector((state) => state.ui.shared.hideFooterMobile);

    // moved from backend and styles, but strange logic to hide it only on last order/ saved carts etc and only on mobile but not in desktop? Since its on bottom dunno why user would care
    if (!isFromMedium && hideFooterMobile) {
        return null;
    }

    const validColumns = footer.columns.filter(
        (column) => !!column.header && !!column.headerLink?.url && column.links.length > 0,
    );

    return (
        <footer className={classNames(styles.Footer, 'u-noPrint')}>
            <div className={classNames('Main-container', !isMobile && 'Main-container--padding')}>
                <div className={styles.FooterPrimaryContainer}>
                    {isMobile ? (
                        <FooterMobile columns={validColumns} />
                    ) : (
                        <FooterColumns columns={validColumns} />
                    )}
                    <div className="u-flex u-flexJustifyCenter u-marginTlg u-marginBlg">
                        <ConsentButton />
                    </div>
                    <div className="u-lg-hidden u-marginBlg">
                        <div className="u-flex u-flexJustifyCenter">
                            <UpscopeTriggerButton />
                        </div>
                    </div>
                </div>

                <div className={styles.FooterPrimaryContainer}>
                    <div className="u-flex u-flexDirectionColumn u-flexJustifySpaceAround u-flexGap16 u-md-flexDirectionRow">
                        <FooterLogos logos={footer.logos} />
                        {props.children}
                    </div>
                </div>

                <div className="Grid Grid--gutterA">
                    <div className="Grid-cell u-md-size2of3">
                        <FooterContact bottomLinks={footer.bottomLinks} />
                    </div>
                    <div className="Grid-cell u-md-size1of3">
                        <FooterSocial />
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default BaseFooter;
